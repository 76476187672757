import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

//Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { LinkButton } from '../components/_parts/LinkButton'



export default () => {
  const queryData = useStaticQuery(graphql`
    {
      allApropos {
        edges {
          node {
            paragraph
          }
        }
      }
      allImageSharp(filter: {fluid: {originalName: {eq: "moi.jpg"}}}) {
        edges {
          node {
            fluid(maxWidth: 395) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const paragraph = queryData.allApropos.edges[0].node.paragraph
  //const imageSource = queryData.allImageSharp.edges[0].node.fluid.src
  const fluidImageObject = queryData.allImageSharp.edges[0].node.fluid

  return (
    <Layout className="page-apropos">
      <SEO title="À Propos" />
      {/* <h1>Page a propos</h1> */}
      <h1 className="page-title">
        <span className="page-title__hollow-bold">À propos</span>
        <br />
        <span className="page-title__red-line">
          Mieux me connaitre <span className="page-title__dot black">.</span>
        </span>
      </h1>
      <hr style={{ border: "solid #B8B8B8 1px", width: "100%", margin: "0 auto" }} />
      <div className="about-block">
        <Img className="about-block__image" fluid={fluidImageObject} style={{ maxWidth: '395px', height: 'auto' }} />
        <p className="about-block__paragraph">{paragraph}</p>
      </div>
      <LinkButton title="DECOUVRIR MES COMPÉTENCES" page="competences" />
    </Layout>
  )
}